import React, { useState } from "react";
import missionimg from "../images/keyboard-5017973-1920-1920x1248 N.jpg";
import Tickicon from "../images/TickIcon.png";
import ServiceHome from "../images/ServiceN2.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../App.css";
import HomeB1 from "../images/Home2.png";
import HomeB2 from "../images/Home3.png";
// import homeB3 from "../images/HOME4.png"
import homeB4 from "../images/home5.png";
import homeB5 from "../images/home6.png";
import homeB6 from "../images/home7.png";
import homeB7 from "../images/home8.png";
import homeB8 from "../images/home9.png";
// import homeB9 from "../images/HomeBannerClotatech.png";
import homeB9 from "../images/Updated banner.png";
import missionAndVission from "../images/MissionVision.png";
import missionAndVissionGold from "../images/MissionVissionGold.png";
import { Dropdown } from "primereact/dropdown";
import weblogo from "../images/3.png";
import locationlogo from "../images/2.png";
import maillogo from "../images/4.png";
import phonelogo from "../images/1.png";
import { Link } from "react-router-dom";
import WebDevelopmentimg from "../images/WebdevBN.png";
import databaseimg1N from "../images/DatabaseBanner.png";
import cloudimg from "../images/Cloud Services.png";
import Additionalimg1 from "../images/additionalServices.jpg";
import AdditionalServices from "../Services/AdditionalServices";

function Home() {
  const [contactName, setContactName] = useState(null);
  const [contactEmail, setContactEmail] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
   const [selectedOption, setSelectedOption] = useState("");
   const [otherSubject, setOtherSubject] = useState("");
  

  const [activelink, setActivelink] = useState("link1");
  const [component1, setComponent1] = useState(true);
  const [component2, setComponent2] = useState(false);
  const [component3, setComponent3] = useState(false);
  const [component4, setComponent4] = useState(false);

  const handleClick = (link) => {
    setActivelink(link);
    setComponent1(link === "link1");
    setComponent2(link === "link2");
    setComponent3(link === "link3");
    setComponent4(link === "link4");
  };

  function ComponentOne(props) {
    return (
      <div style={{ display: props.isVisible ? "block" : "none" }}>
        <div className="homeServicesParent">
          <div className="homeServicesText">
            <i>
              <p>
                We design and construct websites that are effective business
                channels that interact with the target audience, using elegant
                aesthetics and out-of-the-box design concepts. Our websites are
                simple, straightforward and tailored to particular requirements.
              </p>
            </i>
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={() => {
                navigate(`/WebDevelopment`);
                scrollToTop();
              }}
              style={{ width: "155px" }}>
              More Details
            </button>
          </div>

          <div className="homeServicesImg">
            <img
              src={WebDevelopmentimg}
              alt="Description of image"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    );
  }

  function ComponentTwo(props) {
    return (
      <div style={{ display: props.isVisible ? "block" : "none" }}>
        {/* <div className="container"> */}
        <div className="homeServicesParent">
          <div className="homeServicesText">
            <i>
              <p>
                Database Services protects and monitors customer databases by
                establishing backup and recovery procedures, providing a secure
                database environment and monitoring database performance.
              </p>
            </i>
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={() => {
                navigate(`/DatabaseServices`);
                scrollToTop();
              }}
              style={{ width: "165px" }}>
              More Details
            </button>
          </div>

          <div className="homeServicesImg">
            <img
              src={databaseimg1N}
              alt="Description of image"
              className="img-fluid"
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }

  function ComponentThree(props) {
    return (
      <div style={{ display: props.isVisible ? "block" : "none" }}>
        {/* <div className="container"> */}
        <div className="homeServicesParent">
          <div className="homeServicesText">
            <i>
              <p>
                Cloud services, hosted by third-party providers and accessed via
                the internet, enable cloud-native applications and flexible
                work. Our web development team creates tailored websites and
                applications. Businesses no longer need to purchase and manage
                hardware, reducing costs and complexity.
              </p>
            </i>
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={() => {
                navigate(`/CloudServices`);
                scrollToTop();
              }}
              style={{ width: "165px" }}>
              More Details
            </button>
          </div>

          <div className="homeServicesImg">
            <img
              src={cloudimg}
              alt="Description of image"
              className="img-fluid"
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }

  function ComponentFour(props) {
    return (
      <div style={{ display: props.isVisible ? "block" : "none" }}>
        {/* <div className="container"> */}
        <div className="homeServicesParent">
          <div className="homeServicesText">
            <i>
              <p>
                Software tools optimize business processes, document
                development, and improve project productivity. Our software tool
                development approach focuses on meeting company expectations,
                tool effectiveness, integration, choosing the right environment,
                and minimizing the learning curve.
              </p>
            </i>
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={() => {
                navigate(`/AdditionalServices`);
                scrollToTop();
              }}
              style={{ width: "165px" }}>
              More Details
            </button>
          </div>

          <div className="homeServicesImg">
            <img
              src={Additionalimg1}
              alt="Description of image"
              className="img-fluid"
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    closeNavbar();
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  const backgroundMission1 = {
    backgroundImage: `url(${missionimg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "vh",
    width: "100vw",
    // marginTop:'10px'
  };

  const inlineStyles = {
    fontFamily: "Arial",
    fontSize: "32px",

    textalign: "center",
    padding: "20px",
  };

  const validate = () => {
    const errors = {};

    if (!contactName.match(/^[A-Za-z\s]{3,25}$/)) {
      errors.contactName =
        "Name must be 3-25 characters long and contain only alphabets and spaces.";
    }

    if (
      !contactEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      errors.contactEmail = "Please enter a valid email address.";
    }

    if (!contactSubject.match(/^[A-Za-z\s]{3,100}$/)) {
      errors.contactSubject =
        "Subject must be 3-100 characters long and contain only alphabets and spaces.";
    }

    if (contactMessage.length < 3 || contactMessage.length > 300) {
      errors.contactMessage = "Message must be 3-300 characters long.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


 const subjectOptions = [
   { label: "Web Development", value: "Web Development" },
   { label: "Database Service", value: "Database Service" },
   { label: "Cloud Service", value: "Cloud Service" },
   { label: "Additional Service", value: "Additional Service" },
   { label: "Other", value: "Other" },
 ];

 const handleSubjectChange = (e) => {
   const value = e.value;
   setSelectedOption(value);
   if (value !== "Other") {
     setContactSubject(value);
     setErrors({ ...errors, contactSubject: "" });
   } else {
     setContactSubject("");
   }
 };

 const handleOtherSubjectChange = (e) => {
   const value = e.target.value;
   setOtherSubject(value);
   setContactSubject(value);
   if (value.length > 100) {
     setErrors({
       ...errors,
       contactSubject: "Cannot exceed 100 characters",
     });
   } else {
     setErrors({ ...errors, contactSubject: "" });
   }
 };


 const handleSubmit = async (e) => {
  e.preventDefault();

  if (!validate()) {
    toast.error("Please fix the errors in the form.");
    return;
  }

  try {
    const response = await fetch("/saveContactUsEnquiry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: contactName,
        emailID: contactEmail,
        subject: contactSubject,
        message: contactMessage,
      }),
    });

    if (response.status === 200) {
      toast.success("Details Added successfully!");
      setContactName("");
      setContactEmail("");
      setContactSubject("");
      setContactMessage("");
      navigate("/thankyou");
        scrollToTop();
     
    } else {
      toast.error("Some error occurred.");
    }
  } catch (error) {
    toast.error(error.message);
  }
};



  return (
    <>
      <div className="">
        <img
          src={homeB9}
          className=""
          alt="ResponsiveImage"
          style={{ marginTop: "120px", width: "100%" }}
        />
      </div>

      {/* <div className="container " style={{marginTop:'30px'}}>
          <h3 className="head-1" style={inlineStyles}>
            <br />
            <hr></hr>
            <div
              style={{
                color: "#ff8c00",
                textAlign: "center",
                fontFamily: "rockwell",
              }}>
              <b>
              Over a decade of expertise in database technologies and web development.
              </b>
            </div>
            <hr></hr>
          </h3>
        </div>  */}
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <div className="card-body">
                <br />
                <h1
                  className="card-title"
                  style={{ color: "#ff8c00", fontFamily: "rockwell" }}
                >
                  <b> Why Clota ?</b>
                </h1>
                <p className="card-text">
                  <i>
                    Your one-stop shop for digital solutions and marketing,
                    known for creativity, transparency, and strong work ethics.
                  </i>
                </p>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="card-body">
                <br />
                <h6
                  className="card-title"
                  style={{ color: "#ff8c00", fontFamily: "rockwell" }}
                >
                  <img
                    src={Tickicon}
                    alt=""
                    className="img-fluid"
                    style={{
                      width: "40px",
                      height: "30px",
                      marginRight: "10px",
                    }}
                  />
                  <b>
                    <i>We Follow Proven Processes</i>
                  </b>
                </h6>

                <p className="card-text" style={{ paddingLeft: "50px" }}>
                  <i>
                    We craft strategies to grow your business through innovative
                    web designs, apps and marketing campaigns, positioning your
                    brand for success.
                  </i>
                </p>
              </div>

              <br />
              <div className="card-body">
                <h6
                  className="card-title"
                  style={{ color: "#ff8c00", fontFamily: "rockwell" }}
                >
                  <img
                    src={Tickicon}
                    alt=""
                    className="img-fluid"
                    style={{
                      width: "40px",
                      height: "30px",
                      marginRight: "10px",
                    }}
                  />
                  <b>
                    <i>Research And Support</i>
                  </b>
                </h6>
                <p className="card-text" style={{ paddingLeft: "50px" }}>
                  <i>
                    We deliver top-quality solutions with a simple process and
                    are always available for our clients, making us the best
                    choice.
                  </i>
                </p>
              </div>
              <br />
              <div className="card-body">
                <h6
                  className="card-title"
                  style={{ color: "#ff8c00", fontFamily: "rockwell" }}
                >
                  <img
                    src={Tickicon}
                    alt=""
                    className="img-fluid"
                    style={{
                      width: "40px",
                      height: "30px",
                      marginRight: "10px",
                    }}
                  />
                  <b>
                    <i>We Focus On How It Will Profit Your Business</i>
                  </b>
                </h6>
                <p className="card-text" style={{ paddingLeft: "50px" }}>
                  <i>
                    We focus on customer needs, developing strategies that
                    attract and convert new customers, helping brands make a
                    strong impact and increase revenue.
                  </i>
                </p>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      {/* Mission image with title  */}

      <div className="missionVisionBanner">
        <img
          src={missionAndVission}
          // src={missionAndVissionGold}
          className=""
          alt="ResponsiveImage"
          style={{ width: "100%", borderRadius: "20px" }}
        />
      </div>

      {/* Text Vision */}
      <div className="container">
        <h1 style={{ color: "#ff8c00", fontFamily: "rockwell" }}>
          <b>Services</b>
        </h1>
        <div className="area m-4">
          <div
            className={`area-left ${
              activelink === "link1" ? "active-link-css" : ""
            }`}
            onClick={() => handleClick("link1")}
          >
            Web Development
          </div>

          <div
            className={`area-right ${
              activelink === "link2" ? "active-link-css" : ""
            }`}
            onClick={() => handleClick("link2")}
          >
            Database Services
          </div>
          <div
            className={`area-left ${
              activelink === "link3" ? "active-link-css" : ""
            }`}
            onClick={() => handleClick("link3")}
          >
            Cloud Services
          </div>
          <div
            className={`area-right ${
              activelink === "link4" ? "active-link-css" : ""
            }`}
            onClick={() => handleClick("link4")}
          >
            Additional Services
          </div>
        </div>
        <div className="main-section">
          <ComponentOne isVisible={component1} />
          <ComponentTwo isVisible={component2} />
          <ComponentThree isVisible={component3} />
          <ComponentFour isVisible={component4} />
        </div>
      </div>

      <div className="container">
        <div className="row text-white">
          <div className="col-md-6 mt-4">
            <h1
              style={{
                color: "#ff8c00",
                textAlign: "center",
                fontFamily: "rockwell",
              }}
            >
              <b> Let's Talk?</b>
            </h1>
            {/* <br /> */}

            <div className="card-body">
              <h5 className="card-title text-center">Enquiry</h5>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Your Name
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.contactName ? "is-invalid" : ""
                    }`}
                    id="name"
                    name="name"
                    value={contactName}
                    onChange={(e) => {
                      setContactName(e.target.value);
                      if (e.target.value.length > 25) {
                        setErrors({
                          ...errors,
                          contactName: "Cannot exceed 25 characters",
                        });
                      } else {
                        setErrors({ ...errors, contactName: "" });
                      }
                    }}
                    pattern="[A-Za-z\s]+"
                    title="Please enter only alphabets and spaces"
                    required
                  />
                  {errors.contactName && (
                    <div className="invalid-feedback" style={{ color: "red" }}>
                      {errors.contactName}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Your Email
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      errors.contactEmail ? "is-invalid" : ""
                    }`}
                    id="email"
                    name="email"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    title="Please enter a valid email address"
                    required
                  />
                  {errors.contactEmail && (
                    <div className="invalid-feedback">
                      {errors.contactEmail}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                  Categories
                  </label>
                  <Dropdown
                    value={selectedOption}
                    options={subjectOptions}
                    onChange={handleSubjectChange}
                    placeholder="Select a subject"
                    className={`form-control ${
                      errors.contactSubject ? "is-invalid" : ""
                    }`}
                    panelClassName="dropdown-panel-custom"
                  />
                  <br></br>
                  {selectedOption === "Other" && (
                    <input
                      type="text"
                      className={`form-control ${
                        errors.contactSubject ? "is-invalid" : ""
                      }`}
                      id="subject"
                      name="subject"
                      value={otherSubject}
                      onChange={handleOtherSubjectChange}
                      pattern="[A-Za-z\s]+"
                      title="Please enter only alphabets and spaces"
                      required
                    />
                  )}
                  {errors.contactSubject && (
                    <div className="invalid-feedback" style={{ color: "red" }}>
                      {errors.contactSubject}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Your Message
                  </label>
                  <textarea
                    className={`form-control ${
                      errors.contactMessage ? "is-invalid" : ""
                    }`}
                    id="message"
                    name="message"
                    value={contactMessage}
                    onChange={(e) => {
                      setContactMessage(e.target.value);
                      if (e.target.value.length > 300) {
                        setErrors({
                          ...errors,
                          contactMessage: "Cannot exceed 300 characters",
                        });
                      } else {
                        setErrors({ ...errors, contactMessage: "" });
                      }
                    }}
                    required
                  />
                  {errors.contactMessage && (
                    <div className="invalid-feedback" style={{ color: "red" }}>
                      {errors.contactMessage}
                    </div>
                  )}
                </div>

                <div className="text-center homesumbmit">
                  <button type="submit" className="btn btn-outline-warning">
                    Submit
                  </button>
                </div>
                <ToastContainer
                  position="top-center"
                  theme="colored"
                  draggable
                  pauseOnHover
                  closeOnClick
                />
              </form>
            </div>
            <br />
          </div>

          <div className="col-md-6 mt-4">
            <div
              className="text-center"
              style={{ color: "#ff8c00", fontFamily: "rockwell" }}
            >
              <h1>
                <b>Reach Us Directly At</b>
              </h1>
            </div>

            <div className="card bg-black w-75 w-md-50 mx-auto mt-4">
              {/* "card bg-black w-100 w-md-75 mx-auto mt-4" */}
              <div className="card-body contactbox">
                {/* Row 1 */}
                <div className="row mb-3">
                  <div className="col-4 col-md-3">
                    <img
                      src={weblogo}
                      alt="Image 1"
                      className="img-fluid rounded-circle"
                      style={{ width: "60px" }}
                    />
                  </div>
                  <div className="col-8 col-md-9 d-flex align-items-center">
                    <Link
                      to="https://www.clotatech.com/"
                      className="text-warning"
                      style={{
                        color: "white",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}
                      onMouseOver={(e) => (e.target.style.color = "orange")}
                      onMouseOut={(e) => (e.target.style.color = "white")}
                    >
                      <div className="weblink">
                        <span
                          style={{ color: "white", fontFamily: "rockwell" }}
                        >
                          WWW.CLOTATECH.COM
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
                <hr style={{ color: "white" }} />

                {/* Row 2 */}
                <div className="row mb-3">
                  <div className="col-4 col-md-3">
                    <img
                      src={maillogo}
                      alt="Image 2"
                      className="img-fluid rounded-circle"
                      style={{ width: "60px" }}
                    />
                  </div>
                  <div className="col-8 col-md-9 d-flex align-items-center">
                    <Link
                      to="mailto:support@clotatechnology.com"
                      className="text-Warning"
                      style={{
                        color: "white",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}
                      onMouseOver={(e) => (e.target.style.color = "orange")}
                      onMouseOut={(e) => (e.target.style.color = "white")}
                    >
                      support@clotatechnology.com
                    </Link>
                  </div>
                </div>
                <hr style={{ color: "white" }} />

                {/* Row 3 */}
                <div className="row mb-3">
                  <div className="col-4 col-md-3">
                    <img
                      src={phonelogo}
                      alt="Image 3"
                      className="img-fluid rounded-circle"
                      style={{ width: "60px" }}
                    />
                  </div>
                  <div className="col-8 col-md-9 d-flex align-items-center">
                    <Link
                      to="tel:+91 9490384177"
                      className="text-Warning"
                      style={{
                        color: "white",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}
                      onMouseOver={(e) => (e.target.style.color = "orange")}
                      onMouseOut={(e) => (e.target.style.color = "white")}
                    >
                     +919490384177, 
                    </Link>

                    <Link
                      to="tel:+91 9573706658"
                      className="text-Warning"
                      style={{
                        color: "white",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}
                      onMouseOver={(e) => (e.target.style.color = "orange")}
                      onMouseOut={(e) => (e.target.style.color = "white")}
                    >
                     +919573706658, 
                    </Link>

                    <Link
                      to="tel:+91 7569192527"
                      className="text-Warning"
                      style={{
                        color: "white",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}
                      onMouseOver={(e) => (e.target.style.color = "orange")}
                      onMouseOut={(e) => (e.target.style.color = "white")}
                    >
                    +917569192527, 
                    </Link>

                  </div>
                </div>
                <hr style={{ color: "white" }} />

                {/* Row 4 */}
                <div className="row mb-3">
                  <div className="col-4 col-md-3">
                    <img
                      src={locationlogo}
                      alt="Image 4"
                      className="img-fluid rounded-circle"
                      style={{ width: "60px" }}
                    />
                  </div>
                  <div className="col-8 col-md-9">
                    <Link
                      to="https://www.google.com/maps/place/%E0%A4%AA%E0%A4%A6%E0%A5%8D%E0%A4%AE%E0%A4%9C%E0%A4%BE+%E0%A4%AA%E0%A5%88%E0%A4%B0%E0%A4%BE%E0%A4%A1%E0%A4%BE%E0%A4%87%E0%A4%9C/@17.5169463,78.3952708,15z/data=!4m6!3m5!1s0x3bcb8f1d904a1cbd:0x4ab93d4e4aa56850!8m2!3d17.5167738!4d78.3953682!16s%2Fg%2F11hdt52xhd?entry=ttu"
                      className="text-Warning "
                      style={{
                        color: "white",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseOver={(e) => (e.target.style.color = "orange")}
                      onMouseOut={(e) => (e.target.style.color = "white")}
                    >
                      401 Plot # 103, Shilpa Paradise, Pragathi Nagar Hyderabad,
                      Telangana, India 500090
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
