import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";


function ThankYouPage() {
  const navigate = useNavigate();


  useEffect(() => {
    let x = 0;
    const timer = setInterval(() => {
      if (window.location.href.includes("/thankyou")) {
        if (x === 0) {
          window.gtag("event", "conversion", {
            send_to: "AW-16644674753/5RhYCI-brMwZEMGp5oA-",
          });
          x = 1;
        }
        clearInterval(timer);
      }
    }, 1000); // Adding 1000ms delay between each interval check

    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, []);

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <>

      <div class="cookie-card">
        <span class="title">🍪 Thank You</span>
        <p class="description">
    Thank you for your interest in our services. 
    We appreciate your inquiry and will get back to you as soon as possible. 
    Stay tuned for more updates from our team.
</p>



        <div class="actions">

          <button className="accept" onClick={handleGoBack}>
            Go Back
          </button>
        </div>
      </div>
    </>


  );
}

export default ThankYouPage;
