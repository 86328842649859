import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../inc/navbarcssFile.css";
import ClotaLogoHD from "../images/ClotaHDLogoRound.png";
import { MdArrowDropDownCircle } from "react-icons/md";

function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleLinkClick = () => {
    closeMobileMenu();
  };

  const [scrolling, setScrolling] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  
  

  const handleRefresh = () => {
    // Scroll to the top when the page is refreshed
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  window.addEventListener("beforeunload", handleRefresh);


  // per page scroll to top position 
  useEffect(() => {
    const handleScroll = () => {
      
      const scrollPosition = window.scrollY;
      const shouldFixNavbar = scrollPosition > 100;

      setScrolling(shouldFixNavbar);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <nav className="fixed-top">
        <div className="wrapper">
          <div className="logo" onClick={scrollToTop}>
            <img src={ClotaLogoHD} className="img-fluid" alt="clotaLogo" />

            <Link to="/">Clota Technology</Link>
          </div>
          <input
            type="checkbox"
            name="slider"
            id="menu-btn"
            checked={mobileMenuOpen}
            onChange={toggleMobileMenu}
          />
          <input
            type="checkbox"
            name="slider"
            id="close-btn"
            checked={!mobileMenuOpen}
            onChange={toggleMobileMenu}
          />
          <ul className={`nav-links ${mobileMenuOpen ? "mobile-active" : ""}`}>
            <label for="close-btn" className="btn close-btn">
              <i className="fas fa-times"></i>
            </label>
            <li>
              <Link
                to="/"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
              >
                Home
              </Link>
            </li>

            <li>
              <a href="" class="desktop-item">
                Services
                <MdArrowDropDownCircle className="ml-2" />
              </a>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
                Services <MdArrowDropDownCircle />
              </label>
              <ul className="drop-menu">
                <li>
                  <Link
                    to="/WebDevelopment"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                  >
                    Web Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/DatabaseServices"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                  >
                    Database Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/CloudServices"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                  >
                    Cloud Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/AdditionalServices"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                  >
                    Additional Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://helloems.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                  >
                    HRMS Solution
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/Industry"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
              >
                Industry
              </Link>
            </li>
            <li>
              <Link
                to="/Careers"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                to="/Clients"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
              >
                Clients
              </Link>
            </li>
            <li>
              <Link to="https://clotat.blogspot.com/" target="_newtab">
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/About"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/ContactUsForm"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <label for="menu-btn" className="btn menu-btn">
            <i className="fas fa-bars"></i>
          </label>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
