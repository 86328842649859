import React, { useEffect } from "react";
import background1 from "../images/Industrybanner.jpg";
import indimg from "../images/Industrypicbody.png";
import "../../App.css";
function Industry() {
  useEffect(() => {
    scrollToTop();
  }, []); 

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="">
        <img
          src={background1}
          className="img-fluid d-block mx-auto homebanner" 
          alt="Responsive Image"
        />
      </div>
      <br />
      <br />
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center mb-2 ">
            
            <h1 style={{ color: "#ff8c00", fontFamily: "rockwell" }}>
              <strong>Industries We Work </strong>
            </h1>
          </div>
        </div>

        <div className="row text-center">
          <div className="col-md-12">
            <img
              src={indimg}
              className="rounded img-fluid mx-auto d-block industryimage1"
              alt="Centered Image"
              style={{ marginTop: "20px", height: "300px", width: "65%" }}
            />
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card-body text-center">
                <h4 style={{ color: "#ff8c00", fontFamily: "rockwell" }}>
                  <br />
                  <b>
                    <i>Educational Institutions</i>
                  </b>
                </h4>
                <p>
                  <i>
                    Educational Institutions have their core functions to be
                    teaching and research. The impact assessment of a university
                    provides useful information on the nature and magnitude of
                    its contribution to the local communities. We are glad to
                    work and help them providing software and data services.
                  </i>
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card-body text-center">
                <h4 style={{ color: "#ff8c00", fontFamily: "rockwell" }}>
                  <br />
                  <b>
                    <i>Banking And Finance</i>
                  </b>
                </h4>
                <p>
                  <i>
                    Through enterprise architecture, consultancy, and integrated
                    services, we offer next-generation application lifecycle
                    management. We oversee and improve the technological
                    foundation that generates commercial results. We enable
                    improved customer experience across all touchpoints.
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </>
  );
}

export default Industry;
