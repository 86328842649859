import React, { useState } from "react";
import Contactimg1 from "../images/ContactusP.png";
import joincomunity from "../images/background4.jpg";
import { ToastContainer, toast } from "react-toastify";
import "../../App.css";
import { ReactComponent as ContactIcon } from "../images/ContactUsSvg.svg";
import { renderToString } from "react-dom/server";
import weblogo from "../images/3.png";
import locationlogo from "../images/2.png";
import maillogo from "../images/4.png";
import phonelogo from "../images/1.png";
import { Link } from "react-router-dom";
import thankimage from "../images/thank you image.png";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

function ContactUs() {
  const [contactName, setContactName] = useState(null);
  const [contactEmail, setContactEmail] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const svgString = renderToString(<ContactIcon />);
  const [showThankImage, setShowThankImage] = useState(false);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [otherSubject, setOtherSubject] = useState("");
  

  const backgroundImage12 = {
    backgroundImage: `url(${joincomunity})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "25vh",
    width: "100vw",
    marginTop: "10px",
  };

  const validate = () => {
    const errors = {};

    if (!contactName.match(/^[A-Za-z\s]{3,25}$/)) {
      errors.contactName =
        "Name must be 3-25 characters long and contain only alphabets and spaces.";
    }

    if (
      !contactEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      errors.contactEmail = "Please enter a valid email address.";
    }

    if (!contactSubject.match(/^[A-Za-z\s]{3,100}$/)) {
      errors.contactSubject =
        "Subject must be 3-100 characters long and contain only alphabets and spaces.";
    }

    if (contactMessage.length < 3 || contactMessage.length > 300) {
      errors.contactMessage = "Message must be 3-300 characters long.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const [navbarOpen, setNavbarOpen] = useState(false);

 const scrollToTop = () => {
   window.scrollTo({ top: 0, behavior: "smooth" });
   closeNavbar();
 };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

 const subjectOptions = [
   { label: "Web Development", value: "Web Development" },
   { label: "Database Service", value: "Database Service" },
   { label: "Cloud Service", value: "Cloud Service" },
   { label: "Additional Service", value: "Additional Service" },
   { label: "Other", value: "Other" },
 ];

   const handleSubjectChange = (e) => {
     const value = e.value;
     setSelectedOption(value);
     if (value !== "Other") {
       setContactSubject(value);
       setErrors({ ...errors, contactSubject: "" });
     } else {
       setContactSubject("");
     }
   };

   const handleOtherSubjectChange = (e) => {
     const value = e.target.value;
     setOtherSubject(value);
     setContactSubject(value);
     if (value.length > 100) {
       setErrors({
         ...errors,
         contactSubject: "Cannot exceed 100 characters",
       });
     } else {
       setErrors({ ...errors, contactSubject: "" });
     }
   };


   const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validate()) {
      toast.error("Please fix the errors in the form.");
      return;
    }
  
    try {
      const response = await fetch("/saveContactUsEnquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: contactName,
          emailID: contactEmail,
          subject: contactSubject,
          message: contactMessage,
        }),
      });
  
      if (response.status === 200) {
        // Clear form fields
        setContactName("");
        setContactEmail("");
        setContactSubject("");
        setContactMessage("");
  
        console.log("Navigating to thank you page");
        navigate("/thankyou");
        scrollToTop();
  
      } else {
        toast.error("Some error occurred.");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  

  return (
    <>
      <div className="">
        <img
          src={Contactimg1}
          className="img-fluid d-block mx-auto homebanner"
          alt="Responsive Image"
        />
      </div>

      <br />
      <br />

      <div className="container">
        <div className="col-md-12 d-flex align-items-center">
          <div className="HomeMission">
            <h3>
              <strong>Having doubts ? Feel free to Ask!!</strong>
            </h3>

            <br />
            <h5 className="contactheading">
              <strong>
                <i>Thank you for your interest in our services!!!</i>
              </strong>
            </h5>
            <p className="mbr-text mbr-fonts-style display-7">
              <i>
                If you have any questions or queries a member of staff will
                always be happy to help. Feel free to contact us by telephone or
                email and so That way we have your contact information and can
                easily get back to you within a business day to schedule an
                introductory call. We will be sure to get back to you as soon as
                possible.
              </i>
            </p>
            <br />
          </div>
        </div>
      </div>

      {/* New current */}
      <div>
        <div className="container">
          <div className="row text-white ">
            <div className="col-md-6 mt-4">
              <h1
                style={{
                  color: "#ff8c00",
                  textAlign: "center",
                  fontFamily: "rockwell",
                }}
              >
                <b>Let's Talk?</b>
              </h1>
              {/* <br /> */}
              <div className="card-body">
                <h5 className="card-title text-center">Enquiry</h5>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Your Name
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.contactName ? "is-invalid" : ""
                      }`}
                      id="name"
                      name="name"
                      value={contactName}
                      onChange={(e) => {
                        setContactName(e.target.value);
                        if (e.target.value.length > 25) {
                          setErrors({
                            ...errors,
                            contactName: "Cannot exceed 25 characters",
                          });
                        } else {
                          setErrors({ ...errors, contactName: "" });
                        }
                      }}
                      pattern="[A-Za-z\s]+"
                      title="Please enter only alphabets and spaces"
                      required
                    />
                    {errors.contactName && (
                      <div
                        className="invalid-feedback"
                        style={{ color: "red" }}
                      >
                        {errors.contactName}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Your Email
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        errors.contactEmail ? "is-invalid" : ""
                      }`}
                      id="email"
                      name="email"
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      title="Please enter a valid email address"
                      required
                    />
                    {errors.contactEmail && (
                      <div className="invalid-feedback">
                        {errors.contactEmail}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="subject" className="form-label">
                    Categories
                    </label>
                    <Dropdown
                      value={selectedOption}
                      options={subjectOptions}
                      onChange={handleSubjectChange}
                      placeholder="Select a subject"
                      className={`form-control ${
                        errors.contactSubject ? "is-invalid" : ""
                      }`}
                      panelClassName="dropdown-panel-custom"
                    />
                    <br></br>
                    {selectedOption === "Other" && (
                      <input
                        type="text"
                        className={`form-control ${
                          errors.contactSubject ? "is-invalid" : ""
                        }`}
                        id="subject"
                        name="subject"
                        value={otherSubject}
                        onChange={handleOtherSubjectChange}
                        pattern="[A-Za-z\s]+"
                        title="Please enter only alphabets and spaces"
                        required
                      />
                    )}
                    {errors.contactSubject && (
                      <div
                        className="invalid-feedback"
                        style={{ color: "red" }}
                      >
                        {errors.contactSubject}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Your Message
                    </label>
                    <textarea
                      className={`form-control ${
                        errors.contactMessage ? "is-invalid" : ""
                      }`}
                      id="message"
                      name="message"
                      value={contactMessage}
                      onChange={(e) => {
                        setContactMessage(e.target.value);
                        if (e.target.value.length > 300) {
                          setErrors({
                            ...errors,
                            contactMessage: "Cannot exceed 300 characters",
                          });
                        } else {
                          setErrors({ ...errors, contactMessage: "" });
                        }
                      }}
                      required
                    />
                    {errors.contactMessage && (
                      <div
                        className="invalid-feedback"
                        style={{ color: "red" }}
                      >
                        {errors.contactMessage}
                      </div>
                    )}
                  </div>

                  <div className="text-center homesumbmit">
                    <button type="submit" className="btn btn-outline-warning">
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              <br />
            </div>

            <div className="col-md-6 mt-4">
              <div
                className="text-center"
                style={{ color: "#ff8c00", fontFamily: "rockwell" }}
              >
                <h1>
                  <b>Reach Us Directly At</b>
                </h1>
              </div>

              <div className="card bg-black w-75 w-md-50 w-sm-100 mx-auto mt-4">
                <div className="card-body contactbox">
                  {/* Row 1 */}
                  <div className="row mb-3">
                    <div className="col-4 col-md-3">
                      <img
                        src={weblogo}
                        alt="Image 1"
                        className="img-fluid rounded-circle"
                        style={{ width: "60px" }}
                      />
                    </div>
                    <div className="col-8 col-md-9 d-flex align-items-center">
                      <Link
                        to="https://www.clotatech.com/"
                        className="text-warning"
                        style={{
                          color: "white",
                          fontSize: "14px",
                          textDecoration: "none",
                        }}
                        onMouseOver={(e) => (e.target.style.color = "orange")}
                        onMouseOut={(e) => (e.target.style.color = "white")}
                      >
                        <div className="weblink">
                          <span
                            style={{ color: "white", fontFamily: "rockwell" }}
                          >
                            WWW.CLOTATECH.COM
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <hr style={{ color: "white" }} />

                  {/* Row 2 */}
                  <div className="row mb-3">
                    <div className="col-4 col-md-3">
                      <img
                        src={maillogo}
                        alt="Image 2"
                        className="img-fluid rounded-circle"
                        style={{ width: "60px" }}
                      />
                    </div>
                    <div className="col-8 col-md-9 d-flex align-items-center">
                      <Link
                        to="mailto:support@clotatechnology.com"
                        className="text-Warning"
                        style={{
                          color: "white",
                          fontSize: "14px",
                          textDecoration: "none",
                        }}
                        onMouseOver={(e) => (e.target.style.color = "orange")}
                        onMouseOut={(e) => (e.target.style.color = "white")}
                      >
                        support@clotatechnology.com
                      </Link>
                    </div>
                  </div>
                  <hr style={{ color: "white" }} />

                  {/* Row 3 */}
                  <div className="row mb-3">
                    <div className="col-4 col-md-3">
                      <img
                        src={phonelogo}
                        alt="Image 3"
                        className="img-fluid rounded-circle"
                        style={{ width: "60px" }}
                      />
                    </div>
                    <div className="col-8 col-md-9 d-flex align-items-center">
                      <Link
                        to="tel:+91 9490384177"
                        className="text-Warning"
                        style={{
                          color: "white",
                          fontSize: "14px",
                          textDecoration: "none",
                        }}
                        onMouseOver={(e) => (e.target.style.color = "orange")}
                        onMouseOut={(e) => (e.target.style.color = "white")}
                      >
                        +91 9490384177, +919573706658 , +917569192527
                      </Link>
                    </div>
                  </div>
                  <hr style={{ color: "white" }} />

                  {/* Row 4 */}
                  <div className="row mb-3">
                    <div className="col-4 col-md-3">
                      <img
                        src={locationlogo}
                        alt="Image 4"
                        className="img-fluid rounded-circle"
                        style={{ width: "60px" }}
                      />
                    </div>
                    <div className="col-8 col-md-9">
                      <Link
                        to="https://www.google.com/maps/place/%E0%A4%AA%E0%A4%A6%E0%A5%8D%E0%A4%AE%E0%A4%9C%E0%A4%BE+%E0%A4%AA%E0%A5%88%E0%A4%B0%E0%A4%BE%E0%A4%A1%E0%A4%BE%E0%A4%87%E0%A4%9C/@17.5169463,78.3952708,15z/data=!4m6!3m5!1s0x3bcb8f1d904a1cbd:0x4ab93d4e4aa56850!8m2!3d17.5167738!4d78.3953682!16s%2Fg%2F11hdt52xhd?entry=ttu"
                        className="text-Warning "
                        style={{
                          color: "white",
                          fontSize: "14px",
                          textDecoration: "none",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseOver={(e) => (e.target.style.color = "orange")}
                        onMouseOut={(e) => (e.target.style.color = "white")}
                      >
                        401 Plot # 103, Shilpa Paradise, Pragathi Nagar
                        Hyderabad, Telangana, India 500090
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      {/* <br /> */}

      <div className="container-row" style={backgroundImage12}>
        <div className="col-12 text-center">
          <h1 style={{ color: "black", fontSize: "50px" }}>
            Join our community on Clota Forums
          </h1>

          <br />
          <p style={{ color: "black", fontSize: "22px" }}>
            We Design and Develop Experiences That Makes People Lives Simple.
          </p>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}
        >
          <Link
            to="https://clotat.blogspot.com/"
            style={{ textDecoration: "none" }}
          >
            <button className="btn btn-primary style={{ fontFamily: 'Palatino Linotype', sans-serif', fontSize: '16px' }}">
              Connect Now
            </button>
          </Link>
        </div>
      </div>
      <br />
    </>
  );
}

export default ContactUs;
