import React , { useState, useEffect } from "react";
import background2 from "../images/Welcome-photoaidcom-greyscale.jpeg";
import A3Teach1 from "../images/a3-4.png";
import Wylth2 from "../images/wylth.png";
import Cigniti from "../images/Cigniti.png";
import Natinoal from "../images/Natinoal.png";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../App.css";

import Ourpartnerimg from "../images/ourPartners.jpg";

function Clients() {



  const navigate = useNavigate();

  const handleWealthButton = () => {
    const newTab = window.open("https://www.wylth.com/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };
  const handleAThreeTechButton = () => {
    const newTab = window.open("https://www.a3tech.com/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };
  const handleCignityButton = () => {
    const newTab = window.open("https://www.cigniti.com/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };
  const handleJncbButton = () => {
    const newTab = window.open("https://www.jncb.com/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  


  const renderTooltip1 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p style={{ color: "white" }}>
          <i>
          Customers choose us for our quality, affordability, and constant accessibility.
          </i>
        </p>
        <p style={{ color: "white" }}>
          <i>
          We're available via phone, email, or text for any urgent needs or progress updates you may require.
          </i>
        </p>
      </div>
    </Tooltip>
  );

  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p style={{ color: "white" }}>
          <i>
          We follow a meticulous process to ensure top-quality projects.
          </i>
        </p>
        <p style={{ color: "white" }}>
          <i>
          Our team of designers, programmers, and project managers will collaborate with you 
          to transform your idea into a tailored product.
          </i>
        </p>
      </div>
    </Tooltip>
  );

  const renderTooltip3 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p style={{ color: "white" }}>
          <i>
          We create customer-focused strategies to attract and convert new clients.
          </i>
        </p>
        <p style={{ color: "white" }}>
          <i>
          We create effective tactics that drive impact and increase revenue for brands.
          </i>
        </p>
      </div>
    </Tooltip>
  );

  const renderTooltip4 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p style={{ color: "white" }}>
          <i>
            We told you that we have some of the brightest minds working with
            Clota.
          </i>
        </p>
        <p style={{ color: "white" }}>
          <i>
          We design websites, code apps, and run campaigns efficiently, always meeting
           deadlines without exceeding your budget.
          </i>
        </p>
      </div>
    </Tooltip>
  );

  // const isMobile = window.innerWidth < 767; 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Adjust the width as needed for mobile
      
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call on initial load

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const backgroundImageStyle = {
    backgroundImage: `url(${background2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    marginTop: "35px",
    backgroundColor: "rgba(64, 3, 35, 0.8)",
  };

  const imageStyle = {
    filter: "grayscale(100%)",
    transition: "filter 0.3s ease",
  };

  const imageHoverStyle = {
    filter: "grayscale(0%)",
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.filter = "grayscale(0%)";
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.filter = "grayscale(100%)";
  };


  return (
    <>
      
      <div className="">
        <img
          src={Ourpartnerimg}
          className="img-fluid d-block mx-auto homebanner" 
          alt="Responsive Image"
        />
      </div>
      <br />

      <div>
        {/* Latest Code */}
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6 col-md-6 col-lg-3 clientcard1">
              <div className="position-relative">
                <div
                  className="text-Clients"
                  style={{ color: "black", height: "100%" }}>
                  <img
                    src={A3Teach1}
                    className="rounded card-img-top"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body">
                    <br />
                    <p className="card-text" style={{ color: "white" }}>
                      <i>
                        We partner with Financial Advisors who have Passion,
                        Hunger, and Drive. We provide the most comprehensive
                        Platform as a Service (PaaS) for financial advisors
                        covering the entire customer lifecycle.
                        <br />
                        <br />
                        A3Tech Pte Ltd 60 Albert Street, #14-01 OG Albert
                        Complex, Singapore 189969 info@a3tech.com
                      </i>
                    </p>
                    <br />

                    <div className="text-center btn1">
                      <button
                        className="btn btn-outline-warning mt-md-auto"
                        onClick={handleAThreeTechButton}>
                        Visit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-3 clientcard1">
              <div className="position-relative">
                <div
                  className="text-Clients"
                  style={{ color: "black", height: "100%" }}>
                  <img
                    src={Wylth2}
                    className="rounded card-img-top"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body">
                    <br />
                    <p className="card-text" style={{ color: "white" }}>
                      <i>
                        Wylth® is a unified multi-asset platform that aims to
                        serve all the needs of an Independent Financial Advisor
                        / Mutual Fund Distributor.
                        <br />
                        <br />
                        Wylth® Singapore 60 Albert Street, #14-01 OG Albert
                        Complex, Singapore 189969 contact@wylth.com
                      </i>
                    </p>
                    <br />
                    <br />

                    <div className="text-center btn2">
                      <button
                        className="btn btn-outline-warning mt-md-auto"
                        onClick={handleWealthButton}>
                        Visit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-3 clientcard1">
              <div className="position-relative">
                <div className="text-Clients" style={{ color: "black" }}>
                  <img
                    src={Cigniti}
                    className="rounded card-img-top"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body">
                    <br />
                    <p className="card-text" style={{ color: "white" }}>
                      <i>
                        Cigniti Technologies is a global leader in providing
                        AI-driven, IP-led strategic digital assurance, software
                        quality engineering, software testing, automation and
                        consulting services.
                        <br />
                        <br />
                        6th Floor, Orion Block, International Tech Park, Plot
                        No. 17, Software Units Layout, Madhapur, Hyderabad,
                        India – 500081 +91 40 4038 2255
                      </i>
                    </p>

                    <div className="text-center mt-auto btn3">
                      <button
                        className="btn btn-outline-warning mt-md-auto"
                        onClick={handleCignityButton}>
                        Visit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-3 clientcard1">
              <div className="position-relative">
                <div className="text-Clients" style={{ color: "black" }}>
                  <img
                    src={Natinoal}
                    className="rounded card-img-top"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body d-flex flex-column">
                    <br />
                    <p className="card-text" style={{ color: "white" }}>
                      <i>
                        National Commercial Bank Jamaica Limited: provides an
                        array of products & services to meet the banking needs
                        of customers; deposit accounts, unsecured and secured
                        loans, credit cards, overdraft lines, foreign exchange,
                        personal and private banking services are part of the
                        offerings.
                        <br />
                        <br />
                        32 Trafalgar Road, Kingston 10 Jamaica W.I
                      </i>
                    </p>
                    <br />

                    <div className="text-center mt-auto btn4">
                      <button
                        className="btn btn-outline-warning mt-md-auto"
                        onClick={handleJncbButton}>
                        Visit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Reasonsbox">
        <div className="feature text-center" style={backgroundImageStyle}>
          <div className="container">
            <div className="row">
              <h1 style={{ color: "black", fontFamily: "rockwell" }}>
                <b>REASONS WHY CLIENTS CHOOSE US!</b>
              </h1>
              <br />
              <br />

              <div className="col-md-3">
                <OverlayTrigger
                  placement={isMobile ?  'top' : 'right'}
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip1}>
                  <Button
                    className="btn btn-outline-warning boxclient btn-block mb-2"
                    style={{
                      color: "#ff8c00",
                      fontFamily: "rockwell",
                      backgroundColor: "orange",
                      color: "black",
                      height: "80%", 
                      display: "flex",
                      alignItems: "center", 
                      justifyContent: "center",
                    }}>
                    WE ARE ALWAYS AVAILABLE FOR YOU
                  </Button>
                </OverlayTrigger>
                <br />
                <br />
              </div>

              <div className="col-md-3">
                <OverlayTrigger
                  placement={isMobile ?  'top' : 'right'}
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip2}>
                  <Button
                    className="btn btn-outline-warning boxclient btn-block mb-2"
                    style={{
                      color: "#ff8c00",
                      fontFamily: "rockwell",
                      backgroundColor: "orange",
                      color: "black",
                      height: "80%", 
                      display: "flex", 
                      alignItems: "center", 
                      justifyContent: "center",
                    }}>
                    WE FOLLOW PROVEN PROCESSES
                  </Button>
                </OverlayTrigger>
                <br />
                <br />
              </div>

              <div className="col-md-3">
                <OverlayTrigger
                  placement={isMobile ?  'top' : 'left'}
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip3}>
                  <Button
                    className="btn btn-outline-warning btn-block mb-2"
                    style={{
                      color: "#ff8c00",
                      fontFamily: "rockwell",
                      backgroundColor: "orange",
                      color: "black",
                      height: "80%", 
                      display: "flex", 
                      alignItems: "center", 
                      justifyContent: "center",
                    }}>
                    WE FOCUS ON HOW IT WILL PROFIT YOUR BUSINESS
                  </Button>
                </OverlayTrigger>
                <br />
                <br />
              </div>

              <div className="col-md-3">
                <OverlayTrigger
                  placement={isMobile ?  'top' : 'left'}
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip4}>
                  <Button
                    className="btn btn-outline-warning boxclient btn-block mb-2"
                    style={{
                      color: "#ff8c00",
                      fontFamily: "rockwell",
                      backgroundColor: "orange",
                      color: "black",
                      height: "80%", 
                      display: "flex",
                      alignItems: "center", 
                      justifyContent: "center",
                    }}>
                    WE DELIVER ON TIME ON BUDGET
                  </Button>
                </OverlayTrigger>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="text-center">
          <div className="body-card" style={{ marginTop: "50px" }}>
            <h1 style={{ fontFamily: "rockwell", color: "#ff8c00" }}>
              <b> What Clients Says!!</b>
            </h1>
            <br />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-2 mb-2 d-flex justify-content-center">
            <img
              src={Wylth2}
              className="rounded card-img-top"
              alt="..."
              style={{ height: "120px", width: "300px" }}
            />
          </div>

          <div className="col-md-6">
            <p>
              <i>
                We began working with Clota Technologies since Feb 2022
                Fantastic Database Support Partner, Trustworthy, highly skilled
                and experienced team members. We've relied on them exclusively
                since we onboarded them and look forward to continuing that
                relationship. I sleep better knowing Clota has got our backs
                24/7/365
              </i>
              <br />
              <br />
            </p>

            <p style={{ color: "#ff8c00" }}>
              <b>
                <i>
                  Siddhesh Sawant
                  <br />
                  Client
                </i>
              </b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
