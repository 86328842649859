import react, { useRef, useState } from "react";
import WebDevelopment3 from "../images/WebdevBN.png";
import WebDevelopment5 from "../images/development-gbf6798523-1921.png";
import bigdata from "../images/applicationManagement.jpg";
import webdevdesign from "../images/web-development-web-design-.png";
import applicationmanagement from "../images/webThree.png";
import approach from "../images/approach-.png";
import Topimg1 from "../images/SpringBootN.png";
import Topimg2 from "../images/ReactN.jpg";
import Topimg3 from "../images/Node.png";
import Topimg4 from "../images/javaN.jpg";
import Topimg5 from "../images/Topimg5.png";
import Topimg6 from "../images/JavaScriptimg.png";
import Topimg7 from "../images/Topimg7.png";
import TopBootstrap from "../images/Addimg6.png";

import WebVideo from "../../videos/Webdevelopment.gif";
import WebimageN from "../../videos/WEBIMAGE.png"
import "../../App.css";
import Tickicon from "../images/TickIcon.png";


function WebDevelopment() {

  // video gif -----------
  const mediaRef = useRef();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  

  return (
    <>
      <div className="">
        <img
          src={WebDevelopment3}
          className="img-fluid d-block mx-auto homebanner" 
          alt="Responsive Image"
        />
      </div>

      <div className="container webcont1">
        <div className="row ">
          {/* First Column for Text Content */}
          <div className="col-md-6 webcon1N" style={{ marginTop: "8rem" }}>
            <h3
              style={{ color: "#ff8c00", fontFamily: "rockwell" }}
              className="mt-4 mb-4">
              <b>
                <i>Our Development Process</i>
              </b>
            </h3>
            {/* <br /> */}
            <div className="homepara">
              <p>
                <i>
                We craft websites as powerful business channels, 
                engaging audiences with elegant design and unique concepts, precisely tailored to specific needs.
                </i>
              </p>
            </div>
            <br />
          </div>

          <div className="col-md-6 mt-5 WebconN2">
  <div className="feature text-center webvideoplay">
  <p className="text-center text-white" style={{fontWeight:"500"}}>Hover To Play</p>
    <img 
      ref={mediaRef} title="hover to play"
      src={isHovered ? WebVideo : WebimageN}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      alt="My Image"
      className="img-fluid mb-3 rounded"
      style={{ height: "50vh", width: "100%" }}
    />
  </div>
</div>

        </div>
      </div>

      {/* We Shape your web project */}

      <div className="container">
        <div className="row justify-content-center">
          <h3
            style={{ color: "#ff8c00", fontFamily: "rockwell" }}
            className="mt-5 mb-4">
            <b> We Shape Your Web Project</b>
          </h3>

          <div className="mbr-text mbr-fonts-style display-7">
            <strong style={{ color: "#ff8c00", fontFamily: "rockwell" }}>
              <img
                src={Tickicon}
                alt=""
                className="img-fluid"
                style={{
                  width: "40px",
                  height: "30px",
                  marginRight: "10px",
                }}
              />
              <b>
                <i>Custom Application Development : </i>
              </b>
            </strong>
            <span>
              <i>
              We generate standout online design ideas through thorough research on industry trends, competition, and target consumers.
              </i>
            </span>

            <div>
              <strong style={{ color: "#ff8c00", fontFamily: "rockwell" }}>
                <img
                  src={Tickicon}
                  alt=""
                  className="img-fluid"
                  style={{
                    width: "40px",
                    height: "30px",
                    marginRight: "10px",
                  }}
                />
                <b>
                  <i>Application Modernization : </i>
                </b>
              </strong>
              <span>
                <i>
                We turn ideas into seamless online solutions, integrating web services or APIs
                 without compromising performance or user experience.
                </i>
              </span>
            </div>

            <div>
              <strong style={{ color: "#ff8c00", fontFamily: "rockwell" }}>
                <img
                  src={Tickicon}
                  alt=""
                  className="img-fluid"
                  style={{
                    width: "40px",
                    height: "30px",
                    marginRight: "10px",
                  }}
                />
                <b>
                  <i>Application Management Services : </i>
                </b>
              </strong>
              <i>
              We provide enterprise-grade infrastructure and 24/7 support to enhance, upgrade, and maintain 
              your website continuously.
              </i>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Application Development */}
      <div className="container">
        <div className="row">
          <br />
          <div className="col-md-6 d-flex justify-content-center mt-5">
            <br />
            <br />

            <img
              src={WebDevelopment5}
              alt=""
              className="img-fluid"
              style={{ width: "345px", height: "250px", marginTop: "60px" }}
            />
          </div>

          <div className="col-md-6 ">
            <br />
            <h3
              style={{ color: "#ff8c00", fontFamily: "rockwell" }}
              className="mt-5 mb-4">
              <b>Custom Application Development</b>
            </h3>
            {/* <br /> */}
              
              <p>
                <i>
                We develop secure and effective applications tailored to diverse needs using various frameworks and databases. Our experienced
                 full-time developers cater to startups and established enterprises, delivering precision beyond off-the-shelf products.
                </i>
              </p>

            {/* <p>
              <i>
                From dynamic websites to interactive applications ,customized
                software to enterprise web systems by understanding client
                requirements we develop highly secure, reliable & effective
                applications using wide range of framework and databases.{" "}
              </i>
              <br />
            </p>

            <p>
              <i>
              Our experienced web developers work full-time on projects, catering to both 
              startups and established enterprises..{" "}
              </i>
              <br />
            </p>

            <p>
              <i>
              At CLOTA, we tailor solutions to your needs quickly and cost-effectively, 
              surpassing off-the-shelf products in precision.{" "}
              </i>
              <br />
            </p> */}
          </div>
        </div>
      </div>
      <br />

      {/* Top Frameworks We Use: */}

      

      <div className="container mt-4">

      <div className="container text-center mb-4">
        <h1 style={{ color: "#ff8c00", fontFamily: "rockwell" }}>
          <b> Top Frameworks We Use:</b>
        </h1>
      </div>
        <div className="row">
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Topimg1}
                className="card-img-top img-fluid"
                alt="Image 1"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Topimg4}
                className="card-img-top img-fluid"
                alt="Image 2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Topimg2}
                className="card-img-top img-fluid"
                alt="Image 2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Topimg7}
                className="card-img-top img-fluid"
                alt="Image 2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Topimg6}
                className="card-img-top img-fluid"
                alt="Image 2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Topimg3}
                className="card-img-top img-fluid"
                alt="Image 2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={TopBootstrap}
                className="card-img-top img-fluid"
                alt="Image 2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Topimg5}
                className="card-img-top img-fluid"
                alt="Image 2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      <br />

      {/* Application Modrdnizaion */}

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h4
              style={{ color: "#ff8c00", fontFamily: "rockwell" }}
              className="mt-4 mb-4">
              <b>
                <i>Application Modernization</i>
              </b>{" "}
            </h4>
            {/* <br /> */}
            <p>
              <i>
              CLOTA designs secure, scalable web apps and offers turnkey solutions for business app modernization, 
              simplifying Java-based projects with efficient frameworks.
              </i>
            </p>

            {/* <p>
              <i>
                CLOTA can provide a turnkey solution for such business
                application modernization and migration to cutting-edge
                technology platforms.
              </i>
            </p>

            <i>
              <p>
                We reduce development overheads and dial down complexity when
                developing complex Java-based web projects by relying on
                frameworks that include a series of built tools, technical
                components and code samples that eliminate redundant legwork.
              </p>
            </i>
            <br /> */}
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center mt-4">
            <img
              src={bigdata}
              alt=""
              className="rounded-circle img-fluid"
              style={{ width: "440px" }}
            />
          </div>
        </div>
      </div>

      {/* WEB DESIGN THAT IS DEVICE FRIENDLY */}

      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center mt-4">
            <br />

            <img
              src={webdevdesign}
              alt=""
              className="img-fluid"
              style={{ width: "270px", height: "250px" }}
            />
            <br />
          </div>

          <div className="col-md-6">
            <br />
            <h4
              style={{ color: "#ff8c00", fontFamily: "rockwell" }}
              className="mt-4 mb-4">
              <b>
                <i>Web Design That is Device friendly</i>
              </b>
            </h4>
            <p>
              <i>
              We make apps accessible across platforms with responsive design, 
              improving conversion rates across screens with one solution.
              </i>
              <br />
            </p>
          </div>
        </div>
      </div>
      <br />

      {/* Application Management Services */}

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h4
              style={{ color: "#ff8c00", fontFamily: "rockwell" }}
              className="mt-4 mb-4">
              <b>
                <i>Application Management Services</i>
              </b>
            </h4>
            <p>
              <i>
              CLOTA's security and maintenance services keep your web properties fast and secure, 
              while our application management services reduce IT costs for businesses.
              </i>
            </p>

            {/* <p>
              <i>
                CLOTA's application management services enable businesses to
                deliver applications to end users while lowering IT costs.
              </i>
            </p> */}
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center mt-4">
            <img
              src={applicationmanagement}
              alt=""
              className="rounded img-fluid"
              style={{ width: "400px" }}
            />
          </div>
        </div>
      </div>

      {/* Our Approach */}

      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-5">
            <br />
            <h1
              style={{ color: "#ff8c00", fontFamily: "rockwell" }}
              className="mt-4 mb-4">
              <b>Our Approach</b>
            </h1>
            
            <div className="mbr-text mbr-fonts-style display-7">
              <i>
                
                <li>Analyze and evaluate current systems.</li>
                <li>Application Maintenance.</li>
                <li>
                Learn about new business requirements and expectations.
                </li>
                <li>
                  Architect and design the portfolio, Create, Test and Deploy .
                </li>
                <li>
                  Continuously supporting, monitoring and improving performance.
                </li>
                <li>Boosted business productivity.</li>
                <li>Connect to other applications.</li>
                <li>
                  Improved IT and application system quality and reliability.
                </li>
                <li>
                  Learning about new business requirements and expectations.
                </li>
                <li>
                  Reduce the risks associated with people and legacy skills.
                </li>
                <li>Application Enhancements & Integration.</li>
                <li>Eliminate flaws at every construction stage.</li>
                <li>
                Ship tested solutions to prevent business losses during production.
                </li>
              </i>
            </div>
            <br />
          </div>

          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
            <br />
            <br />

            <img
              src={approach}
              alt=""
              className="img-fluid"
              style={{ width: "400px", height: "300px" }}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default WebDevelopment;
